
.team-item{
    position: relative;
    overflow: hidden;
    &__image{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: scale(1);
        filter: blur(0);
        transition: .25s ease-out;
    }
    &__inner{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, .5);
        opacity: 1;
        visibility: visible;
        transition: .25s ease-out;
    }
    &__active{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .8);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: .25s ease-out;
        >*{
            transform: scale(1.4);
            opacity: 0;
            visibility: hidden;
            transition: .25s ease-out;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .team-item{
        &:hover{
            .team-item__image{
                transform: scale(1.1);
                filter: blur(10px);
            }
            .team-item__inner{
                opacity: 0;
                visibility: hidden;
            }
            .team-item__active{
                opacity: 1;
                visibility: visible;
                &>*{
                    transform: scale(1);
                    opacity: 1;
                    visibility: visible;
                    transition: .35s ease-out;
                    &:nth-child(1){
                        transition-delay: .1s;
                    }
                    &:nth-child(2){
                        transition-delay: .2s;
                    }
                    &:nth-child(3){
                        transition-delay: .3s;
                    }
                }
            }
        }
    }
}
@media (min-width:1200px){
}

