
::v-deep {
    &.swiper-button-next,
    &.swiper-button-prev {
        margin-top: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        &::before {
            width: 100%;
            height: 100%;
        }
        &::after,
        .v-ripple__container {
            display: none;
        }
        &:hover:before,
        &:focus:before {
            opacity: 0.15 !important;
        }
    }
    &.swiper-button-prev {
        left: var(--container-gutter);
    }
    &.swiper-button-next {
        right: var(--container-gutter);
    }
}
.swiper-btn-wrap {
    .swiper-button-next,
    .swiper-button-prev {
        ::v-deep {
            position: relative !important;
            top: auto !important;
            bottom: auto !important;
            left: auto !important;
            right: auto !important;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
