
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .news-item{
        &__image{
            transform: scale(1);
            transition: .2s ease-out;
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, .4);
                top: 0;
                left: 0;
                opacity: 0;
                visibility: hidden;
                transition: .2s ease-out;
            }
        }
        &:hover{
            .news-item__image{
                transform: scale(1.2);
                &::after{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
@media (min-width:1200px){
}

