
.swiper-pagination--fraction {
    font-size: 0;
    line-height: 1;
    color: rgb(var(--v-theme-grey-darken-4));
    :deep() {
        .swiper-pagination {
            &-current,
            &-total {
                color: currentColor;
            }
            &-total,
            &__decoration {
                opacity: 0.2;
            }
            &-current {
                font-size: 2.6rem;
                font-weight: 700;
                padding-right: 8px;
            }
            &-total {
                font-size: 1.4rem;
                padding-left: 8px;
            }
            &__decoration {
                display: block;
                width: 1px;
                height: 12px;
                transform: rotate(15deg);
                background-color: currentColor;
            }
        }
    }

    &.swiper-pagination--light {
        :deep() {
            .swiper-pagination {
                &-total,
                &__decoration {
                    opacity: 0.4;
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .swiper-pagination--fraction {
        :deep() {
            .swiper-pagination {
                &-current {
                    font-size: 3.2rem;
                    padding-right: 10px;
                }
                &-total {
                    font-size: 1.8rem;
                    padding-left: 10px;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
