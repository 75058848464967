
::v-deep {
    .gnb--filled {
        .gnb {
            &__item {
                &.active,
                &:hover {
                    .gnb__link {
                        color: #fff;
                        background-color: var(--v-primary-base);
                    }
                }
            }
        }
    }
}
