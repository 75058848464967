
.swiper-pagination--fraction-divider {
    font-size: 0;
    line-height: 1;
    color: rgb(var(--v-theme-grey-darken-4));
    :deep() {
        .swiper-pagination {
            &-current,
            &-total {
                font-size: 1.8rem;
                font-weight: 700;
                color: currentColor;
            }
            &-total,
            &__decoration {
                opacity: 0.2;
            }
            &-current {
                padding-right: 12px;
            }
            &-total {
                padding-left: 12px;
            }
            &__decoration {
                display: block;
                width: 1px;
                height: 10px;
                background-color: currentColor;
            }
        }
    }

    &.swiper-pagination--light {
        :deep() {
            .swiper-pagination {
                &-total,
                &__decoration {
                    opacity: 0.4;
                }
            }
        }
    }
}
