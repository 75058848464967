
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .site-item{
        overflow: hidden;
        &__logo{
            transform: scale(1);
            transition: .15s ease-out;
        }
        &:hover{
            .site-item__logo{
                transform: scale(1.12);
                background-color: var(--v-grey-lighten5);
            }
        }
    }
}
@media (min-width:1200px){
}
