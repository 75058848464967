
.swiper-pagination--ellipse {
    ::v-deep {
        .swiper-pagination {
            &-bullet {
                &-active {
                    width: 30px;
                    border-radius: 9999px;
                }
            }
        }
    }

    &.swiper-pagination {
        &--sm {
            ::v-deep {
                .swiper-pagination {
                    &-bullet {
                        &-active {
                            width: 20px;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .swiper-pagination--ellipse {
        ::v-deep {
            .swiper-pagination {
                &-bullet {
                    &-active {
                        width: 40px;
                    }
                }
            }
        }

        &.swiper-pagination {
            &--sm {
                ::v-deep {
                    .swiper-pagination {
                        &-bullet {
                            &-active {
                                width: 28px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
