
.swiper-pagination--around-border {
    ::v-deep {
        .swiper-pagination {
            &-bullet {
                position: relative;
                background-color: transparent;
                border: 0;
                opacity: 1;
                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    display: block;
                    border-radius: 100%;
                    background-color: currentColor;
                }
                &::before {
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    opacity: 0.2;
                }
                &::after {
                    z-index: -1;
                    width: 20px;
                    height: 20px;
                    opacity: 0;
                }
                &-active {
                    margin: 0 10px;
                    &::before,
                    &::after {
                        opacity: 1;
                    }
                    &::before {
                        background-color: #fff;
                    }
                }
            }
        }
    }

    &.swiper-pagination {
        &--sm {
            ::v-deep {
                .swiper-pagination {
                    &-bullet {
                        width: 8px;
                        height: 8px;
                        &::after {
                            width: 18px;
                            height: 18px;
                        }
                        &-active {
                            margin: 0 10px;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .swiper-pagination--around-border {
        ::v-deep {
            .swiper-pagination {
                &-bullet {
                    width: 12px;
                    height: 12px;
                    &::after {
                        width: 24px;
                        height: 24px;
                    }
                    &-active {
                        margin: 0 12px;
                    }
                }
            }
        }

        &.swiper-pagination {
            &--sm {
                ::v-deep {
                    .swiper-pagination {
                        &-bullet {
                            width: 8px;
                            height: 8px;
                            &::after {
                                width: 18px;
                                height: 18px;
                            }
                            &-active {
                                margin: 0 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
