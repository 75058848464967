
.swiper-pagination--filled-outlined {
    ::v-deep {
        .swiper-pagination {
            &-bullet {
                opacity: 1;
                background-color: transparent;
                &-active {
                    background-color: currentColor;
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
