
.lnb--topline ::v-deep {
    top: calc(var(--header-body-height) - 4px);
    border-top: 4px solid var(--v-grey-darken4);
    > li {
        border-left: 1px solid var(--border-color);
        border-right: 1px solid var(--border-color);
        &:last-child {
            border-bottom: 1px solid var(--border-color);
        }
        > a {
            &:hover {
                color: var(--v-grey-darken4);
                background-color: var(--v-grey-lighten5);
            }
        }
    }
}
