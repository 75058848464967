
// Underline
.tit-wrap--underline {
    padding-bottom: 14px;
    margin-bottom: var(--tit-wrap-padding-bottom);
    border-bottom: 2px solid var(--v-primary-base);
    &.tit-wrap{
        &--sm{
            margin-bottom: var(--tit-wrap-padding-bottom--sm);
        }
        &--lg{
            margin-bottom: var(--tit-wrap-padding-bottom--lg);
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .tit-wrap--underline {
        border-bottom: 3px solid var(--v-primary-base);
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
