
// 해당되는 스타일만 복사해서 사용해주세요.
// Filled
.tab-filled {
    color: var(--v-grey-base) !important;
}

.tabs-filled2 {
    background-color: transparent !important;
    &:before {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }
}
.tab-filled2 {
    color: #fff !important;
    background-color: transparent !important;
    &.v-tab {
        &--active {
            color: var(--v-primary-base) !important;
            background-color: #fff !important;
        }
    }
}

.tabs-filled3 {
    background-color: var(--v-primary-base) !important;
}
.tab-filled3 {
    color: #fff !important;
    background-color: transparent !important;
    &.v-tab {
        &--active {
            color: var(--v-primary-base) !important;
            background-color: #fff !important;
        }
    }
}

.tabs-filled4 {
    background-color: transparent !important;
    &:before {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }
}
.tab-filled4 {
    color: #fff !important;
    background-color: transparent !important;
    &.v-tab {
        &--active {
            color: #fff !important;
            background-color: rgba(255, 255, 255, 0.2) !important;
        }
    }
}

.tabs-filled5 {
    background-color: var(--v-primary-base) !important;
}
.tab-filled5 {
    color: #fff !important;
    background-color: transparent !important;
    &.v-tab {
        &--active {
            color: #fff !important;
            background-color: rgba(255, 255, 255, 0.2) !important;
        }
    }
}

// Slider
.tabs-slider,
.tabs-slider2 {
    background-color: transparent !important;
    &:before {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }
}
.tab-slider,
.tab-slider2 {
    color: #fff !important;
    &.v-tab {
        &--active {
            color: #fff !important;
        }
    }
}

// Button
.tab-button {
    color: var(--tab-color) !important;
    background-color: transparent !important;
}

// Punch
.tab-punch{
    color: var(--v-grey-base) !important;
    background-color: var(--v-grey-lighten5) !important;
}
