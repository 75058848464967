
.swiper-btn-wrap {
    .swiper-button-prev,
    .swiper-button-next {
        position: static !important;
        top: auto;
        transform: none;
    }

    &--bottom,
    &--left,
    &--right {
        position: absolute !important;
    }
    &--bottom {
        bottom: 20px;
    }
    &--left{
        left: var(--container-gutter);
    }
    &--right{
        right: var(--container-gutter);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .swiper-btn-wrap {
        &--bottom {
            bottom: 50px;
        }
    }
}
@media (min-width: 1024px) {
    .swiper-btn-wrap {
        &--bottom {
            bottom: 80px;
        }
    }
}
@media (min-width: 1200px) {
}
