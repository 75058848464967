
.header-search {
    --header-search-color: var(--v-grey-darken4);
    --header-search-color-light: 255, 255, 255;

    ::v-deep {
        position: relative;
        .v-text-field {
            &.v-input {
                & > .v-input__control > .v-input__slot,
                &--density-comfortable > .v-input__control > .v-input__slot {
                    padding-right: var(--input-height-comfortable) !important;
                }
                &--density-compact > .v-input__control > .v-input__slot {
                    padding-right: var(--input-height-compact) !important;
                }
                &--density-default > .v-input__control > .v-input__slot {
                    padding-right: var(--input-height) !important;
                }
            }
        }
    }
    &__input {
        max-width: 200px;
        & + .header-search__btn {
            position: absolute;
            top: 50%;
            right: 0;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            width: var(--input-height-comfortable) !important;
            height: 100% !important;
        }
    }
    &__btn,
    &__btn.theme--light.v-btn.v-btn--icon,
    ::v-deep .v-text-field:not(.v-input--is-focused):not(.v-input--has-state).theme--light > .v-input__control > .v-input__slot fieldset {
        color: var(--header-search-color);
    }
    ::v-deep .v-text-field.theme--light > .v-input__control > .v-input__slot:before {
        border-color: var(--header-search-color);
    }

    &--light {
        &,
        .header-search__btn,
        .header-search__btn.theme--light.v-btn.v-btn--icon,
        ::v-deep .v-text-field.theme--light > .v-input__control > .v-input__slot fieldset,
        ::v-deep .v-input.theme--light,
        ::v-deep .v-input.theme--light input,
        ::v-deep .v-input.theme--light textarea {
            color: rgb(var(--header-search-color-light)) !important;
        }
        ::v-deep {
            .v-text-field.theme--light > .v-input__control > .v-input__slot {
                &::before,
                &::after {
                    border-color: rgb(var(--header-search-color-light));
                }
            }

            input,
            textarea {
                &::placeholder {
                    color: rgba(var(--header-search-color-light), 0.7) !important;
                    opacity: 1;
                }
                &:-ms-input-placeholder {
                    color: rgba(var(--header-search-color-light), 0.7) !important;
                }
                &::-ms-input-placeholder {
                    color: rgba(var(--header-search-color-light), 0.7) !important;
                }
            }
        }
    }
}
