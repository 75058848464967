
.swiper-pagination--text-divider {
    --swiper-pagination-bullet-width: 20px;
    --swiper-pagination-bullet-margin: 8px;
    @media (min-width: 576px) {
    }
    @media (min-width: 768px) {
        --swiper-pagination-bullet-width: 40px;
        --swiper-pagination-bullet-margin: 12px;
    }
    @media (min-width: 1024px) {
    }
    @media (min-width: 1200px) {
    }

    color: rgb(var(--v-theme-grey-darken-4));

    :deep() {
        .swiper-pagination {
            &-bullet {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                width: auto;
                height: auto;
                margin: 0 var(--swiper-pagination-bullet-margin);
                font-size: 1.4rem;
                line-height: 1.2;
                font-weight: 700;
                opacity: 0.2;
                background-color: transparent;
                border: 0;
                border-radius: 0;
                &::before {
                    display: none;
                }
                &::after {
                    content: "";
                    position: static;
                    display: block;
                    width: 0;
                    height: 1px;
                    background-color: currentColor;
                    opacity: 0;
                    -webkit-transform: scaleX(0);
                    -ms-transform: scaleX(0);
                    transform: scaleX(0);
                    transform-origin: left;
                    -webkit-transition: all ease-out 0.4s;
                    -ms-transition: all ease-out 0.4s;
                    transition: all ease-out 0.4s;
                }
                &-active {
                    opacity: 1 !important;
                    &::after {
                        width: var(--swiper-pagination-bullet-width);
                        margin-left: calc(var(--swiper-pagination-bullet-margin) * 2);
                        opacity: 1 !important;
                        -webkit-transform: scaleX(1);
                        -ms-transform: scaleX(1);
                        transform: scaleX(1);
                    }
                }
            }
        }
    }

    &.swiper-pagination--light {
        :deep() {
            .swiper-pagination {
                &-bullet {
                    opacity: 0.4;
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
